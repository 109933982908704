import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class UserWalletService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    getUserCurrentBalance(userId) {
        let url = `${this.#api}/${userId}/wallet`;
        return apiService.get(url);
    }
}
