<template>
<v-app>
  <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? 'Update' : 'Add'}` }} invoice particular
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-card-text>
              <v-row>
                  <v-col cols="12">
                      <v-text-field label="Title" v-model="type.title" outlined dense>
                      </v-text-field>
                      <span class="text-danger" v-if="$v.type.title.$error">This information is required</span>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field label="Amount (monthly)"  @input="onChangeTotalAmount" v-model="type.amount" type="number" outlined dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field label="Duration time" @input="onChangeTotalAmount" v-model="type.duration_time"  type="number" outlined dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field label="Total amount" v-model="type.total_amount"  type="number" outlined dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    Description
                    <ckeditor v-model="type.description"  :config="editorConfig"></ckeditor>
                    <span class="text-danger"
                          v-if="$v.type.description.$error">This information is required</span>
                  </v-col>

                  <v-col cols="6" v-if="!transactionHeadId">
                      <v-select
                              :items="financial_heads"
                              v-model="type.transaction_head_id"
                              outlined
                              dense
                              label="Finance head"
                              item-text="title"
                              item-value="id"
                      >

                      </v-select>
                      <span class="text-danger"
                            v-if="$v.type.transaction_head_id.$error">This information is required</span>

                  </v-col>

                  <v-col cols="12">
                    <v-switch
                        v-model="type.is_active"
                        label="Status"
                    >
                    </v-switch>
                  </v-col>

                  <v-col cols="12">
                      <div class="text-right float-right">
                          <v-btn depressed @click="closeDialog" class="text-gray btn btn-standard cancel-btn">
                              Cancel
                          </v-btn>
                          <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                 class="text-white ml-2 btn btn-primary ">
                            {{ edit ? "Update" : "Save" }}
                          </v-btn>
                      </div>

                  </v-col>
              </v-row>

          </v-card-text>
      </v-card>
  </v-dialog>
</v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import FinanceMixin from "@/mixins/FinanceMixin";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";

const financialPaymentTypeService = new FinancialPaymentTypeService();

export default {
  name: 'types',
  props: ['transactionHeadId'],
  mixins: [FinanceMixin],
  validations: {
      type: {
        title: {required},
        amount: {required},
        description: {required},
        transaction_head_id: {required},
      }
  },
  data() {
     return {
          dialog: false,
          edit: false,
          sizeExceed: false,
          isBusy: false,
          search: {
              active: "active",
              is_parent: 1,
          },
          types:[
              {
                  title:'Expense',
                  value:'expense'
              },
              {
                  title:'Income',
                  value:'income'
              },
          ],
          type: {
              title: null,
              description: null,
              transaction_head_id: null,
              amount: null,
              duration_time: null,
              total_amount: null,
              is_active: true
          },
        editorConfig: {
          versionCheck: false,
          toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ]
        },
      };
  },
  mounted() {
    this.getTransactionHeadings();
  },
  methods: {
    onChangeTotalAmount() {
      if(this.type.amount && this.type.duration_time) {
        this.type.total_amount = parseInt(this.type.duration_time * this.type.amount);
      } else {
        this.type.total_amount = 0
      }
    },
    showModal(id = null) {
        this.resetForm();
        if (id) {
            this.edit = true
            this.__get(id)
        } else
            this.edit = false;
        this.dialog = true;
    },

    hideModal() {
        this.dialog = false;
    },
    toggleModal() {
        this.$refs["my-modal"].toggle("#toggle-btn");
    },
    __get(id) {
        financialPaymentTypeService.show(id).then(response => {
            this.type = response.data.paymentType
        })
    },

    createOrUpdate() {
        this.$v.type.$touch();
        this.type.transaction_head_id = this.transactionHeadId;
        if (this.$v.type.$error) {
            setTimeout(() => {
                this.$v.type.$reset();
            }, 3000);
        } else {
            let fd = this.type;
            if (this.edit) {
                this.__update(fd);
            } else {
                this.__create(fd);
            }
        }
    },
    __update(fd) {
        this.isBusy = true;
        financialPaymentTypeService
            .update(this.type.id,fd)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$emit("refresh_type");
                this.closeDialog();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
                // console.log(error);
            });
    },

    checkSize(file) {
        let size = file.size / 1024 / 1024;
        let mbSize = size.toFixed(2);
        this.sizeExceed = mbSize > 20;
    },
    __create(fd) {
        this.isBusy = true;
        financialPaymentTypeService
            .store( fd)
            .then((response) => {
                this.isBusy = false;
                this.closeDialog();
                this.$emit("refresh_type");
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
    },
    closeDialog() {
      this.hideModal();
    },
    resetForm() {
      this.$v.type.$reset();
      this.edit = false;
      this.type = {
        title: null,
        description: null,
        transaction_head_id: null,
        amount: null,
        duration_time: null,
        total_amount: null,
        is_active: true
      };
    }
  }
};
</script>
