import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import FinancialHistoryService from "@/core/services/finance/history/FinancialHistoryService";
import UserWalletService from "@/core/services/user/wallet/UserWalletService";

const financialTransactionHeadsService = new FinancialTransactionHeadsService();
const paymentTypeService = new FinancialPaymentTypeService();
const siteSettingService = new SiteSettingService();
const historyService = new FinancialHistoryService();
const walletService = new UserWalletService();

const FinanceMixin = {
    data() {
        return {
            financial_heads: [],
            financial_payment_types: [],
            page: null,
            perPage: null,
            total: null,
            setting: {},
            financial_payment_type: {},
            search: {},
            current_balance: 0

        }
    },
    methods: {
        getCurrentBalance(userId) {
            walletService.getUserCurrentBalance(userId).then(response => {
                if(response.data.data) {
                    this.current_balance = response.data.data
                }
            })
        },
        getTransactionHeadings() {
            financialTransactionHeadsService
                .paginate(this.search)
                .then(response => {
                    this.financial_heads = response.data.data;
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        getAllActiveTransactionHead(type) {
            financialTransactionHeadsService
                .allActiveByType(type)
                .then(response => {
                    this.financial_heads = response.data;
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        getPaymentType(event ) {
            this.history_item.transaction_head_id = event.id
            this.history_item.transaction_head_title = event.title
            paymentTypeService
                .allActiveByTransactionHead(this.history_item.transaction_head_id)
                .then(response => {
                    this.financial_payment_types = response.data
                })
                .catch(() => {});
        },
        getPaymentTypeById(id) {
            if(id != 'other') {
                paymentTypeService
                    .show(id)
                    .then(response => {
                        this.financial_payment_type = response.data.paymentType;
                        this.history_item.quantity = 1;
                        this.history_item.payment_type_title = this.financial_payment_type.title
                        this.history_item.item_price = this.financial_payment_type.amount;
                        this.calculateTotal();
                    })
                    .catch(() => {});
            }
        },
        getSetting() {
            siteSettingService.show().then(response => {
                this.setting = response.data
                if (this.history_id) {
                    this.getHistory();
                }
            })
        },

        getHistories(search, index) {
            historyService.paginate(search, index).then(response => {
                this.transaction_histories = response.data.data
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        }
    },
}
export default FinanceMixin;
